import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';

import headerBg from 'images/element/services/header-bg.png';
import headerText from 'images/element/services/header-text.svg';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Dimension from 'components/dimension';

const ComponentText = {
  READMORE: 'read more',
};

const Services = ({ data }) => {
  let services = [];
  if (data.types.group && data.types.group.length > 0) {
    services = data.types.group[0].edges;
  }

  return (
    <Layout
      renderContent={() => {
        return (
          <>
            <SEO title="Services" />
            <div
              className="flex flex-col w-full bg-white max-w-screen-xl"
              style={{
                marginTop: Dimension.NAVBAR_HEIGHT,
              }}
            >
              <div className="relative">
                <img
                  src={headerBg}
                  alt="header background"
                  className="object-cover w-full h-auto"
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      src={headerText}
                      alt="Meet our expert team"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center w-full bg-white">
                <div className="w-full px-4 py-10 grid grid-cols-1 gap-8 max-w-screen-lg md:grid-cols-2">
                  {services.map(sv => {
                    const { frontmatter } = sv.node;

                    return (
                      <div
                        key={frontmatter.path}
                        className="'flex flex-col overflow-hidden text-gray-900 bg-white border shadow"
                      >
                        <Img
                          alt="Featured"
                          fluid={
                            frontmatter.featuredImage.childImageSharp.fluid
                          }
                          className="w-full h-48"
                        />

                        <div className="flex flex-col p-5 overflow-hidden">
                          <h4 className="text-lg font-semibold truncate">
                            {frontmatter.title}
                          </h4>
                          <div className="w-full mt-2 text-base">
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: sv.node.snippet,
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end px-4 mb-4">
                          <button
                            type="button"
                            className="text-white uppercase bg-secondary hover:bg-teal-800"
                            onClick={() => navigate(frontmatter.path)}
                          >
                            <div className="flex items-stretch">
                              <div className="flex items-center w-8 h-8 bg-main">
                                <div className="flex items-center justify-center w-full">
                                  <FontAwesomeIcon
                                    className="text-xs"
                                    icon={['fas', 'chevron-right']}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center justify-center px-2 text-sm">
                                {ComponentText.READMORE}
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};
export default Services;

export const pageQuery = graphql`
  {
    types: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 100
      filter: { frontmatter: { type: { eq: "services" } } }
    ) {
      group(field: frontmatter___type) {
        fieldValue
        edges {
          node {
            snippet
            html
            frontmatter {
              path
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
